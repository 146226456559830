import React, { useEffect } from 'react';
import './Home.css';

const Home = () => {

  useEffect(() => {
    document.body.style.backgroundColor = 'white';
  }, []);

  return (
    <div className="container">
      <div className="grid-container">
        <div class="logo" onClick={() => window.location.href = '/scowl'}><img src="./images/logos/scowl-dance.png" alt="Scowl Halloween Dance Party" /></div>
        <div class="logo"><img src="./images/logos/sparkle-dance.png" alt="Sparkle New Years Dance Party" /></div>
        <div class="logo" onClick={() => window.location.href = '/solstice'}><img src="./images/logos/solstice-dance.png" alt="Solstice Summer Dance Party" /></div>
        <div class="logo"><img src="./images/logos/goblin-dance.png" alt="Goblin Teenager Halloween Dance Party" /></div>
        <div class="logo"><img src="./images/logos/glitter-dance.png" alt="Glitter Teenager New Years Dance Party" /></div>
        <div class="logo" onClick={() => window.location.href = '/glow'}><img src="./images/logos/glow-dance.png" alt="Glow Teenager Summer Dance Party" /></div>
      </div>
      <div className="grid-container">
        
      </div>
    </div>
  )
}

export default Home;