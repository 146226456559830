import React, { useState, useEffect } from 'react';
import '../App.css';
import styled from 'styled-components';
import { Analytics } from '@vercel/analytics/react';
import dayjs from 'dayjs';
import 'primeicons/primeicons.css';

const AppContainer = styled.div`
  background-color: white;
`;

const HeroSection = styled.section`
  & {
    display: flex;
  }

  & > #info-container {
    display: flex;
    flex-direction: column;
    background-color: black;
    color: white;
  }

  & > #info-container > .top * {
    color: white;
  }

  & > #info-container > .top p {
    font-weight: 100;
  }

  & > #info-container > .top {
    margin: 45px;
  }

  @media (min-width: 800px) {
    & > #info-container {
      padding-top: 15px;
    }
  }

  & > div:nth-child(1) {
    height: auto;
    flex: 4;
  }

  & > div:nth-child(1) > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  & > div:nth-child(2) {
    flex: 4;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > div:nth-child(2) > h1 {
    font-size: 4.8rem;
    font-weight: 900;
    color: black;
    margin-bottom: 10px;
  }

  & > div:nth-child(2) > p {
    color: black;
    display: flex;
  }

  & > div:nth-child(2) > p > span:nth-child(1),
  & > div:nth-child(2) > p > span:nth-child(3), {
    font-weight: 900;
    font-size: 2.5rem;
  }

  & > div:nth-child(2) > p > span:nth-child(2), {
    margin: 0px;
    font-size:1rem;
  }

  & div#instructions.hidden {
    color: transparent;
    transition: color .5s;
    font-weight: normal;
  }

  & div#instructions {
    padding: 5px 15px;
    font-family: 'Barlow Semi Condensed', sans-serif;
  }

  & div#instructions.hidden ::marker {
    color: transparent;
    font-weight: bold;
    transition: color .5s;
  }

  & div#instructions.visible ::marker {
    color: #8f8f8f;
    font-weight: bold;
    transition: color .5s;
  }

  & div#instructions.hidden a {
    color: transparent;
    text-decoration: none;
    transition: color .5s, border-color .5s;
    font-weight: bold;
    padding: 5px 15px;
    border: 1px solid;
    border-color: transparent;
    border-radius: 34px;
  }

  & div#instructions.hidden strike {
    color: transparent;
    transition: color .5s;
  }

  & div#instructions.visible strike {
    color: red;
    transition: color .5s;
  }

  & div#instructions.visible {
    color: white;
    transition: color .5s;
    font-weight: normal;
  }

  & div#instructions.visible a {
    color: #57c6ff;
    text-decoration: none;
    transition: color .5s, opacity .5s, border-color .5s;
    font-weight: bold;
    padding: 5px 15px;
    border: 1px solid;
    border-radius: 34px;
    opacity: 1;
    border-color: #57c6ff;
  }

  & div#instructions.visible a:hover {
    opacity: .7;
  }

  & div#qr-venmo {
    text-align: center;
  }

  & div#continue-to-venmo {
    text-align: right;
    padding: 10px 0px;
  }

  & div#instructions.visible div#qr-venmo {
    opacity: 1;
    transition: opacity .5s;
  }

  & div#instructions.hidden div#qr-venmo {
    opacity: 0;
    transition: opacity .5s;
  }

  @media (max-width: 800px) {
    & {
      height: 1171px;
      flex-direction: column
    }
  }

  @media (max-width: 1148px) {
    & > div:nth-child(2) > h1 {
      font-size: 3rem;
      font-weight: 900;
      color: black;
      margin-bottom: 10px;
    }

    & > div:nth-child(2) > p > span:nth-child(1),
    & > div:nth-child(2) > p > span:nth-child(3) {
      font-size: 1.5rem;
    }
  }

  @media (max-width: 800px) {
    div#qr-venmo {
      display: none;
    }
  }

  @media (min-width: 801px) {
    div#continue-to-venmo {
      display: none;
    }
  }
`;

const TicketButton = styled.button`
  & {
    padding: 10px 36px;
    font-weight: 900;
    font-size: 1.25rem;
    border-radius: 38px;
    background-color: #ebb56e;
    cursor: pointer;
    display: flex;
    align-items: center;
    opacity: 1;
    transition: opacity 1s;
    color: black !important;
  }

  &:hover {
    opacity: .7;
    transition: opacity 1s;
  }

  & > img {
    height: 55px;
    padding: 0px 10px 0px 0px;
  }

  @media (max-width: 1148px) {
    & > img {
      height: 32px;
      padding: 0px 7px 0px 0px;
    }

    & {
      font-size: 1rem;
    }
  }
`;


const handleToggleInstructions = () => {
  const instructions = document.querySelector('div#instructions');
  if(instructions.className === 'hidden') {
    instructions.className = 'visible';
  } else {
    instructions.className = 'hidden';
  }
}

// configure a JWT auth client
// let jwtClient = new google.auth.JWT(
//   privatekey.client_email,
//   null,
//   privatekey.private_key,
//   ['https://www.googleapis.com/auth/spreadsheets']);
// //authenticate request
// jwtClient.authorize(function (err, tokens) {
// if (err) {
//   console.log(err);
// return;
// } else {
//   console.log('Successfully connected!');
// }
// });

// //Google Sheets API
// let spreadsheetId = '1j-f7Ql3yRCuAipVV1tRGdCQr1_FU0mamDexmqvpK3pM';
// let sheetName = 'Sparkle Goals'
// let sheets = google.sheets('v4');
// sheets.spreadsheets.values.get({
//    auth: jwtClient,
//    spreadsheetId: spreadsheetId,
//    range: sheetName
// }, function (err, response) {
//    if (err) {
//        console.log('The API returned an error: ' + err);
//    } else {
//        console.log('Movie list from Google Sheets:');
//        for (let row of response.values) {
//            console.log('Title [%s]ttRating [%s]', row[0], row[1]);
//        }
//    }
// });

function Solstice() {
  const [countDown, setCountDown] = useState();
  const date = dayjs(new Date());
  const dayOf = dayjs('2024-06-20 00:00:00');
  const todayIsTheDay = date >= dayOf;
  const priceElement = todayIsTheDay
    ? <><strong>$30</strong></>
    : <><strike>$30</strike> <strong>$25</strong></>;
  const ticketButtonText = todayIsTheDay
    ? 'CLICK TO GET TICKETS'
    : 'GET PRESALE TICKETS';

  useEffect(() => {
    document.body.style.backgroundColor = 'white';
    document.body.style.color = 'black';
    document.querySelector('#info-container').classList.add('light');
  }, []);

  return (
    <AppContainer>
      <HeroSection style={{marginBottom: '75px'}}>
        {/* <FadeSlider /> */}
        <div><img src="/images/solstice-frontpage.jpg" /></div>
        {/* <SmartSlider slides={[{url: '/images/sparkle-dance-1.jpg'}, {url: '/images/sparkle-dance-2.jpg'}, {url: '/images/sparkle-dance-3.jpg'}]} autoSlide={true} showIndicators={false} /> */}
        {/* <div><img alt="Scowl Group" src="/images/scowl-group-4-2023.jpg" /></div> */}
        <div id="info-container">
          <div className="top">
            <h2>Join Us for an Epic Summer Women’s Dance!</h2>
            <p>Get ready to dance the night away at the event you won’t want to miss! Calling all amazing women to come together for a spectacular celebration of friendship, empowerment, and joy!</p>
            <p>
              🌸 What: Epic Summer Women’s Dance<br/>
              📅 When: June 21, 2024<br/>
              🕖 Time: 8pm
            </p>
            <p>Come and experience the magic of sisterhood as we lift each other up and dance our hearts out under the summer sky. Whether you’re coming solo, with friends, or with your tribe, everyone is welcome!</p>
            <p>This is not just a dance; it’s a celebration of the incredible strength and support that women bring into each other’s lives. Let’s create unforgettable memories together as we move, groove, and celebrate the power of unity.</p>
          </div>
          
          <div>
            <TicketButton onClick={handleToggleInstructions} href="#"><img alt="Scowl Logo" src="/images/scowl-logo.png" /> <span>{ticketButtonText}</span></TicketButton>
          </div>
          <div id="instructions" className="hidden">
            <p>Venmo Instructions</p>
            <p>
              <ol>
                <li>Go to "Payments/Requests".</li>
                <li>Select "Friend to Friend" for payment method.</li>
                <li>Pay {priceElement} for each ticket.</li>
                <li>In description, type "Solstice" and ticket qty.</li>
              </ol>
              
            </p>
            <p style={{textAlign:'center'}}>ALL SALES ARE FINAL 🙂</p>
            <div id="continue-to-venmo"><a target="_blank" rel="noreferrer" href="https://account.venmo.com/u/Jessica-JolleyGreene">Continue with Venmo</a></div>
            <div style={{textAlign: 'center'}} id="qr-venmo"><img alt="Venmo QR Code" style={{width:'160px'}} src="/images/qr-venmo.jpg" /></div>
          </div>
        </div>
      </HeroSection>
      <Analytics />
    </AppContainer>
  );
}

export default Solstice;